import React from 'react';

import { View } from '../../../common/components/View/View';

import { EditContributionsForm } from '../components/EditProductForm/EditProductForm';

export const EditProductPage: React.FC = () => (
  <View type="form">
    <EditContributionsForm />
  </View>
);
