import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_LIST_USERS } from '../../../common/constants/api';

import { IDevsList } from '../typings/devs.type';
import { getProduct } from '../../../common/utils/local-storage';

export const apiDevsList = async ({ params = {} }: IApiRequest): Promise<IDevsList | null> =>
  // FIXME: Добавить функционал продукта
  await httpGET<IDevsList>({ url: API_LIST_USERS, params: Object.assign(params, { product: getProduct() }) });
