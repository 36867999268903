import React from 'react';

import { LogoWrapper } from '../LogoWrapper/LogoWrapper';
import { Menu } from '../Menu/Menu';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';

import s from './HeaderAuth.module.scss';

export const HeaderAuth: React.FC = () => (
  <header className={s.headerWrapper}>
    <LogoWrapper />
    <Menu />
    <ProfileMenu />
  </header>
);
