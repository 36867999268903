import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../Icon/Icon';

import s from './FormHeading.module.scss';

interface IFormHeading {
  title: string;
  backArrow: boolean;
  loading?: boolean;
}

export const FormHeading: React.FC<IFormHeading> = ({ loading = false, title, backArrow }) => {
  const history = useHistory();

  return (
    <header className={s.formHeader}>
      {backArrow && (
        <div
          className={s.backArrow}
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          <Icon name="arrow-long-left" />
        </div>
      )}
      <h2 className={s.heading}>{!loading ? title : 'Loading...'}</h2>
    </header>
  );
};
