import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_GET_USER_ME } from '../../../common/constants/api';

import { IDevs } from '../typings/devs.type';

export const apiGetDevMe = async ({ options }: IApiRequest): Promise<IDevs | null> =>
  // FIXME: Добавить функционал продукта
  await httpGET<IDevs>({ url: API_GET_USER_ME, options });
