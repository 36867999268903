import React, { useEffect, useState } from 'react';

import { IDevsListFilterProps } from '../../typings/devs.type';

import { TableFilterWrapper } from '../../../../common/components/TableFilterWrapper/TableFilterWrapper';
import { Input } from '../../../../common/components/Input/Input';
import { Link } from '../../../../common/components/Link/Link';
import { Select } from '../../../../common/components/Select/Select';
import { getProduct } from '../../../../common/utils/local-storage';
import { apiProductList } from 'src/features/products/api/api-product-list';
import { IProduct } from 'src/features/products/typings/products.type';
// import { RangeDate } from '../../../../common/components/RangeDate/RangeDate';
// import { MultiSelect } from '../../../../common/components/MultiSelect/MultiSelect';

export const TableFilterDevsList: React.FC<IDevsListFilterProps> = ({ disabled, props, filterUpdate, resetFilter }) => {
  const [products, setProducts] = useState<Array<{ value: string; label: string }>>([]);

  // FIXME: упаковать в hook и убрать в mobx
  useEffect(() => {
    const getProducts = async () => {
      const products = await apiProductList({});
      if (products) {
        setProducts(
          products.data.map((product: IProduct) => ({
            value: product.id,
            label: product.name,
          })),
        );
      }
    };
    getProducts();
  }, []);

  return (
    <TableFilterWrapper>
      <div>
        <Input
          value={props.searchQuery}
          placeholder={'Search by name / email / github'}
          name="searchQuery"
          onChange={(event) => {
            filterUpdate(null, 'searchQuery', event.target.value);
          }}
          icon={'search'}
          allowClear
          disabled={disabled}
        />
      </div>
      <div>
        <Select
          disabled={disabled}
          allowClear
          value={props.activeAt}
          placeholder={'Registration type'}
          options={[
            { value: 'true', label: 'Self registerd' },
            { value: 'false', label: 'Created by admin' },
          ]}
          onChange={(val) => {
            filterUpdate(null, 'activeAt', val);
            // filterUpdate(null, 'type', val);
          }}
        />
      </div>
      {/* <div style={{ display: 'none' }}>
        <MultiSelect
          value={filterState.category}
          placeholder={'Category'}
          onChange={(val) => {
            filterUpdate(null, 'category', val);
          }}
        />
      </div> */}
      {(props.searchQuery !== '' || props.activeAt) && (
        <div className={'reset-center'}>
          <Link
            to="#"
            type="reset-filter"
            onClick={(e) => {
              e.preventDefault();
              resetFilter();
            }}
          >
            Reset filter
          </Link>
        </div>
      )}
    </TableFilterWrapper>
  );
};
