import React from 'react';

import { ILayout } from '../typings/layout.type';
import { HeaderAuth } from '../components/HeaderAuth/HeaderAuth';
import { FooterAuth } from '../components/FooterAuth/FooterAuth';
import { MainContentAuth } from '../components/MainContentAuth/MainContentAuth';

export const AuthLayout: React.FC<ILayout> = ({ children }) => (
  <>
    <HeaderAuth />
    <MainContentAuth>{children}</MainContentAuth>
    <FooterAuth />
  </>
);
