import React from 'react';
import { Select as AntSelect } from 'antd';
import { Label } from '../Label/Label';
import { IInputDefault } from '../../typings/input.type';

interface IMultiSelect extends IInputDefault {
  value: string | string[];
  onChange: (val: string | string[]) => void;
  options: Array<{ value: string; label: string | number }>;
  // api
}

export const MultiSelect: React.FC<IMultiSelect> = ({ options, label, placeholder, value, id, onChange }) => {
  const WrapperInput = label ? Label : React.Fragment;
  const props = label ? { title: label } : {};
  return (
    <WrapperInput {...props}>
      <AntSelect
        mode="tags"
        style={{ minWidth: '150px' }}
        value={value}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
      >
        {options &&
          options.map((option) => (
            <AntSelect.Option key={option.value} value={option.value}>
              {option.label}
            </AntSelect.Option>
          ))}
      </AntSelect>
    </WrapperInput>
  );
};
