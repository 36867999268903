import { notification } from 'antd';

interface IOpenNotification {
  type: 'success' | 'error';
  message: string;
  description: string;
  onClick?: () => void;
}

export const openNotification = ({
  type = 'success',
  message = '',
  description = '',
  onClick,
}: IOpenNotification): void => {
  const notificationConfig = {
    message: message,
    description: description,
    onClick: onClick,
  };

  notification[type](notificationConfig);
};
