import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Helmet from 'react-helmet';

import './App.scss';

import { RouterProvider } from '../router/RouterProvider';

export const App: React.FC = () => (
  <>
    <Helmet
      htmlAttributes={{ lang: 'en', amp: undefined }}
      titleTemplate={`%s | Admin console - Apache Ignite Recognition System`}
      defaultTitle={`Admin console - Apache Ignite Recognition System`}
    />
    <Router>
      <RouterProvider />
    </Router>
  </>
);
