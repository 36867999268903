import React from 'react';
import { Input as AntInput } from 'antd';

import { Label } from '../Label/Label';
import { InputError } from '../InputError/InputError';
import { IInputDefault } from '../../typings/input.type';

import s from './Input.module.scss';

interface ITextArea extends IInputDefault {
  rows?: number;
  icon?: string;
  allowClear?: boolean;
  value: string;
  label?: string;
  image?: string;
  error?: string;
  onChange?: (val: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: React.FC<ITextArea> = ({
  allowClear = false,
  disabled,
  placeholder,
  value,
  name,
  id,
  label,
  onChange,
  required = false,
  error = '',
  rows,
}) => {
  const WrapperInput = label ? Label : React.Fragment;
  const props = label ? { title: label, required } : {};
  return (
    <WrapperInput {...props}>
      <AntInput.TextArea
        id={id}
        rows={rows}
        name={name}
        value={value}
        onChange={onChange}
        className={s.input}
        disabled={disabled}
        allowClear={allowClear}
        placeholder={placeholder || ''}
      />
      {error && <InputError text={error} />}
    </WrapperInput>
  );
};
