import { IRoute } from '../typings/route.type';
// import { MainPage } from '../../features/main/pages';
import { ListContributionsPage, EditContributionsPage } from '../../features/contributions/pages';
import { ListProductsPage, EditProductPage } from 'src/features/products/pages';
import { MailingListPage } from '../../features/mailing/pages';
import { ListDevsPage, EditDevsPage } from '../../features/devs/pages';
import { LoginPage, LogoutPage } from '../../features/login/pages';
import {
  URL_CONTRIBUTIONS,
  URL_PRODUCTS,
  URL_PEOPLE,
  URL_SETTINGS,
  URL_LOGIN,
  URL_LOGOUT,
  URL_MAILING_LIST,
} from './url';

export const GUEST_ROUTES: IRoute[] = [
  // {
  //   path: '/',
  //   exact: true,
  //   component: MainPage,
  // },
  {
    path: URL_LOGIN,
    exact: true,
    component: LoginPage,
  },
];
export const GUEST_DEFAULT_ROUTE = URL_LOGIN;

export const AUTH_ROUTES: IRoute[] = [
  {
    path: URL_CONTRIBUTIONS,
    exact: true,
    component: ListContributionsPage,
  },
  {
    path: `${URL_CONTRIBUTIONS}/:prefix`,
    exact: true,
    component: EditContributionsPage,
  },
  {
    path: URL_PRODUCTS,
    exact: true,
    component: ListProductsPage,
  },
  {
    path: `${URL_PRODUCTS}/:prefix`,
    exact: true,
    component: EditProductPage,
  },
  {
    path: URL_PEOPLE,
    exact: true,
    component: ListDevsPage,
  },
  {
    path: `${URL_PEOPLE}/:prefix`,
    exact: true,
    component: EditDevsPage,
  },
  {
    path: URL_SETTINGS,
    exact: true,
    component: ListContributionsPage,
  },
  {
    path: URL_MAILING_LIST,
    exact: true,
    component: MailingListPage,
  },
  {
    path: URL_LOGOUT,
    exact: true,
    component: LogoutPage,
  },
];
export const AUTH_DEFAULT_ROUTE = URL_CONTRIBUTIONS;
