import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { GUEST_ROUTES, GUEST_DEFAULT_ROUTE } from '../../common/constants/routes';
import { GuestLayout } from '../../common/layouts/GuestLayout';

export const GuestRoutes: React.FC = () => (
  <GuestLayout>
    <Switch>
      {GUEST_ROUTES.map((routeItem, index) => (
        <Route key={index} path={routeItem.path} exact={routeItem.exact} component={routeItem.component} />
      ))}
      <Redirect to={GUEST_DEFAULT_ROUTE} />
    </Switch>
  </GuestLayout>
);
