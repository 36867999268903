import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_GET_CONTRIBUTION } from '../../../common/constants/api';

import { IContributions } from '../typings/contributions.type';
import { getProduct } from '../../../common/utils/local-storage';

export const apiGetContribution = async ({ options }: IApiRequest): Promise<IContributions | null> =>
  // FIXME: Добавить функционал продукта
  await httpGET<IContributions>({
    url: API_GET_CONTRIBUTION,
    options,
    params: Object.assign({}, { product: getProduct() }),
  });
