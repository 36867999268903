import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Checkbox, Popconfirm } from 'antd';

import { Form } from '../../../../common/components/Form/Form';
import { FormRow } from '../../../../common/components/FormRow/FormRow';
import { FormCell } from '../../../../common/components/FormCell/FormCell';
import { Input } from '../../../../common/components/Input/Input';
import { TextArea } from '../../../../common/components/Input/TextArea';
import { Link } from '../../../../common/components/Link/Link';

import { IProductEdit, IProductFormError } from '../../typings/products.type';

import { apiGetProduct } from '../../api/api-get-products';
import { apiAddProduct } from '../../api/api-add-product';
import { apiEditProduct } from '../../api/api-edit-product';
import { apiRemoveProduct } from '../../api/api-remove-product';

import { URL_PRODUCTS } from '../../../../common/constants/url';

import { openNotification } from '../../../../common/utils/notification';

export const EditContributionsForm: React.FC = () => {
  const params = useParams<{ [key: string]: string }>();
  const history = useHistory();

  const [title, setTitle] = useState<string>('');
  const [loading, setLoading] = useState({ edit: true, remove: false });
  const [loadingData, setLoadingData] = useState(true);

  const [formValue, setFormValue] = useState<IProductEdit>({
    name: '',
    description: '',
    private: false,
  });

  const [formValueError, setFormValueError] = useState<IProductFormError>({
    name: '',
    description: '',
    private: '',
  });

  useEffect(() => {
    const getProduct = async () => {
      const product = await apiGetProduct({ options: { id: params.prefix } });
      if (product) {
        setFormValue({
          id: product.id?.toString(),
          name: product.name,
          aboutContentMd: product.aboutContentMd,
          description: product.description,
          stackOverflowTags: product.stackOverflowTags,
          apacheLists: product.apacheLists,
          private: product.private,
        });
        setLoading({ edit: false, remove: false });
        setTitle(product.name);
        setLoadingData(false);
      }
    };

    if (params.prefix === 'add') {
      setTitle('New Contribution');
      setLoading({ edit: false, remove: false });
    } else {
      getProduct();
    }
  }, [params.prefix]);

  const formChangeHandler = (
    e?: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    if (e) {
      setFormValueError(Object.assign({}, formValueError, { [e.target.name]: '' }));
      return setFormValue(Object.assign({}, formValue, { [e.target.name]: e.target.value }));
    }
  };

  const handlerSubmitForm = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();
    setLoading({ edit: true, remove: false });

    if (!formValue.name) {
      return setFormValueError(Object.assign({}, formValueError, { name: 'name and id is required' }));
    }

    let status = null;
    const body = {
      ...formValue
    };

    if (params.prefix === 'add') {
      status = await apiAddProduct({ body });
    } else if (formValue.id) {
      status = await apiEditProduct({ body, options: { id: formValue.id } });
    }

    setLoading({ edit: false, remove: false });
    if (status && status.id) {
      openNotification({
        type: 'success',
        message: '',
        description: `Product has been ${params.prefix === 'add' ? 'created' : 'updated'}`,
      });
      return history.push(URL_PRODUCTS);
    }
  };

  const handlerRemoveProduct = async (): Promise<void> => {
    setLoading({ edit: false, remove: true });
    if (formValue.id) {
      const status = await apiRemoveProduct({ options: { id: formValue.id } });
      if (status && status.id) {
        openNotification({
          type: 'success',
          message: 'Info',
          description: `Product has been removed`,
        });
        setLoading({ edit: false, remove: false });
        return history.push(URL_PRODUCTS);
      }
    }
  };

  return (
    <Form title={title} loading={params.prefix !== 'add' ? loadingData : false} backArrow>
      <FormRow>
        <FormCell>
          <Input
            label={'Name'}
            name="name"
            allowClear
            placeholder={'Name'}
            value={formValue.name}
            onChange={formChangeHandler}
          />
        </FormCell>
        <FormCell>
          <Input
            label={'ID / Slug'}
            name="id"
            allowClear
            disabled={true}
            placeholder={'ID'}
            value={formValue.id || formValue.name.toLowerCase().replaceAll(' ', '_')}
            onChange={formChangeHandler}
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <TextArea
            label={'Short description'}
            name="description"
            allowClear
            placeholder={'Description'}
            value={formValue.description}
            onChange={formChangeHandler}
          />
        </FormCell>
      </FormRow>

      <FormRow>
        <FormCell>
          <Input
            label={'Stack Overflow Search Tags'}
            name="stackOverflowTags"
            allowClear
            placeholder={'Stack Overflow Search Tags'}
            value={formValue.stackOverflowTags || ''}
            onChange={formChangeHandler}
          />
        </FormCell>
        <FormCell>
          <Input
            label={'Apache user lists'}
            name="apacheLists"
            allowClear
            placeholder={'Apache user lists'}
            value={formValue.apacheLists || ''}
            onChange={formChangeHandler}
          />
        </FormCell>
      </FormRow>



      <FormRow>
        <FormCell>
          <TextArea
            label={'About Content Markdown'}
            name="aboutContentMd"
            allowClear
            rows={10}
            placeholder={'About Content Markdown'}
            value={formValue.aboutContentMd || ''}
            onChange={formChangeHandler}
          />
        </FormCell>
      </FormRow>

      <FormRow>
        <FormCell>
          <Checkbox
            checked={formValue.private}
            onChange={(e) => setFormValue(Object.assign({}, formValue, { private: e.target.checked }))}
          >
            Is Private
          </Checkbox>
        </FormCell>
      </FormRow>

      <FormRow>
        <FormCell>
          <Link
            type="ant-form-save"
            disabled={!formValue.name}
            loading={loading.edit}
            to={'#'}
            onClick={handlerSubmitForm}
          >
            {params.prefix === 'add' ? 'Create product' : 'Update product'}
          </Link>
          {params.prefix !== 'add' && (
            <Popconfirm
              placement="left"
              title={'Are you sure want to delete this product?'}
              onConfirm={(e) => {
                e?.preventDefault();
                handlerRemoveProduct();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Link type="ant-form-archive" loading={loading.remove} to={'#'}>
                Delete product
              </Link>
            </Popconfirm>
          )}
        </FormCell>
      </FormRow>
    </Form>
  );
};
