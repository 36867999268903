import React from 'react';
import moment from 'moment';
import { Checkbox } from 'antd';

import { TableFilterWrapper } from '../../../../common/components/TableFilterWrapper/TableFilterWrapper';
import { Link } from '../../../../common/components/Link/Link';
import { Select } from '../../../../common/components/Select/Select';
import { Input } from '../../../../common/components/Input/Input';
import { RangeDate } from '../../../../common/components/RangeDate/RangeDate';

import { IMailingsListFilterProps } from '../../typings/mailings.type';

export const STATUS_OPTIONS = [
  { value: 'new', label: 'New' },
  { value: 'ignored', label: 'Ignored' },
  { value: 'published', label: 'Published' },
];

export const TableFilterMainlingList: React.FC<IMailingsListFilterProps> = ({
  disabled,
  props,
  filterUpdate,
  resetFilter,
}) => (
  <TableFilterWrapper>
    <div>
      <Input
        value={props.searchQuery}
        placeholder="Search by title"
        name="searchQuery"
        onChange={(event) => {
          filterUpdate(null, 'searchQuery', event.target.value);
        }}
        icon="search"
        disabled={disabled}
        allowClear
      />
    </div>

    <div>
      <Select
        value={props.status}
        placeholder="Any status"
        options={STATUS_OPTIONS}
        allowClear
        onChange={(val) => {
          filterUpdate(null, 'status', val);
        }}
      />
    </div>

    <div>
      <RangeDate
        disabled={disabled}
        value={
          props.dateFrom
            ? `${moment(props.dateFrom).format('YYYY-MM-DD')}/${moment(props.dateTo).format('YYYY-MM-DD')}`
            : ''
        }
        onChange={(val) => {
          if (val) {
            const dates = val.split('/');
            filterUpdate(null, '', {
              dateFrom: moment(dates[0], 'YYYY-MM-DD').format('YYYY-MM-DD'),
              dateTo: moment(dates[1], 'YYYY-MM-DD').format('YYYY-MM-DD'),
            });
          } else {
            filterUpdate(null, '', {
              dateFrom: '',
              dateTo: '',
            });
          }
        }}
      />
    </div>

    <div>
      <Checkbox
        checked={props.hideFiltered}
        onChange={(e) => {
          filterUpdate(null, 'hideFiltered', e.target.checked);
        }}
      >
        Hide filtered
      </Checkbox>
    </div>

    {(!!props.searchQuery || !!props.dateFrom || !!props.dateTo) && (
      <div className="reset-center">
        <Link
          to="#"
          type="reset-filter"
          onClick={(e) => {
            e.preventDefault();
            resetFilter();
          }}
        >
          Reset filter
        </Link>
      </div>
    )}
  </TableFilterWrapper>
);
