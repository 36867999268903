import { IObject } from '../typings/object.type';

export const createUrl = (url: string, params?: IObject): string => {
  let newUrl = url;

  if (params) {
    Object.keys(params).map((key) => {
      newUrl = newUrl.replace(`:${key}`, params[key]?.toString() || '');
    });
  }

  return newUrl;
};

export const isValidHttpUrl = (str: string): boolean => {
  let url;

  try {
    url = new URL(str);
  } catch (e) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};
