import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';

import s from './RangePicker.module.scss';

interface IRangeDate {
  value: string;
  name?: string;
  id?: string;
  disabled?: boolean;
  bordered?: boolean;
  onChange: (val: string) => void;
}

export const RangeDate: React.FC<IRangeDate> = ({ bordered = true, disabled, value, name, id, onChange }) => {
  const { RangePicker } = DatePicker;

  const handlerChange = (values: RangeValue<moment.Moment>, formatString: [string, string]) => {
    const range = formatString.join('/');
    onChange(range === '/' ? '' : range);
  };

  const createArrayDate = (dateString: string): RangeValue<moment.Moment> | undefined => {
    const t = dateString.split('/');
    if (!t) return undefined;
    return [moment(t[0]), moment(t[1])];
  };

  return (
    <RangePicker
      id={id}
      bordered={bordered}
      disabled={disabled}
      name={name}
      className={s.rangePicker}
      separator={''}
      value={value ? createArrayDate(value) : undefined}
      ranges={{
        Today: [moment(), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      }}
      onChange={handlerChange}
    />
  );
};
