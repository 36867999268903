import React from 'react';

import s from './Heading.module.scss';

interface IHeading {
  title: string;
  btnBlock?: React.ReactNode;
  infoBlock?: React.ReactNode;
}

export const Heading: React.FC<IHeading> = ({ title, btnBlock, infoBlock }) => (
  <div className={s.heading}>
    <div className={s.titleWrapper}>
      <h2 className={s.title}>{title}</h2>
      {infoBlock}
    </div>
    {btnBlock}
  </div>
);
