import queryString from 'query-string';

import { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from './useDebounced';

import { ISortList, IPageList, IList } from '../typings/list.type';
import { IApiFn } from '../typings/api.type';
import { IObject } from '../typings/object.type';
import { ITableCreateProps } from '../typings/table-function.type';

import {
  tableCreateFilter,
  tableCreateSort,
  tableCreatePage,
  createApiParams,
  tableResetPage,
} from '../utils/table-function';

import { COUNT_ROWS_LIST } from '../constants/list';

interface IUseTableReturn<T, F> {
  listData: IList<T>;
  filterData: F;
  sortData: ISortList;
  pageData: IPageList;
  loading: boolean;
  loadingLoadMore: boolean;
  setSort: (sort: ISortList) => void;
  setFilter: ITableCreateProps<F>;
  nextPage: () => void;
  resetFilter: () => void;
  refreshData: () => void;
}

export const useTable = <T, F extends IObject>(
  api: IApiFn<IList<T>>,
  filterInit: F,
  defaultSort?: ISortList,
): IUseTableReturn<T, F> => {
  const location = useLocation();
  const urlParams = queryString.parse(location.search, { arrayFormat: 'bracket' });

  const [listData, setListData] = useState<IList<T>>({ count: 0, totalCount: 0, data: [] });
  const [loading, setLoading] = useState(true);
  const [changePage, setChangePage] = useState(false);
  const [waitReloadData, setWaitReloadData] = useState(false);

  const [sortData, setSortData] = useState<ISortList>({
    column: urlParams.sortBy ? urlParams.sortBy.toString() : defaultSort?.column || null,
    dir:
      urlParams.sortBy && (urlParams.sort === 'DESC' || urlParams.sort === 'ASC')
        ? urlParams.sort
        : defaultSort?.dir || 'DESC',
  });

  const [pageData, setPageData] = useState<IPageList>({
    offset: urlParams.offset ? +urlParams.offset : 0,
    limit: urlParams.limit ? +urlParams.limit : COUNT_ROWS_LIST,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterUrlInit: any = {};
  Object.keys(urlParams).forEach((param) => {
    if (param && param in filterInit) {
      filterUrlInit[param] = urlParams[param];
    }
  });

  const [filterData, setFilterData] = useState<F>(Object.assign({}, filterInit, filterUrlInit));
  const debouncedFilter = useDebounce<F>(filterData, 300);

  useEffect(() => {
    //FIXME: Убрать ссылочность
    const fetchList = async () => {
      if (waitReloadData) {
        setWaitReloadData(false);
        return;
      }
      if (!changePage) setLoading(true);
      const params = createApiParams<F>(sortData, pageData, debouncedFilter);
      const data = await api({ params: params.api });

      // Убираем лишний рендер
      window.history.pushState(null, '', `${location.pathname}${params.url ? `?${params.url}` : ''}`);

      setLoading(false);
      if (data) {
        if (changePage) {
          setListData({ count: data.count, totalCount: data.totalCount, data: listData.data.concat(data.data) });
        } else {
          setListData(data);
        }
      }
      setChangePage(false);
      // FIXME: логика ошибок
    };

    fetchList();
    // eslint-disable-next-line
  }, [sortData, pageData, debouncedFilter, location.pathname]);

  const setSort = useCallback(
    (newSort) => {
      setSortData(tableCreateSort(newSort, sortData));
    },
    [sortData],
  );

  const setFilter = useCallback(
    (e, name, val) => {
      setWaitReloadData(true);
      setPageData(tableResetPage());
      setFilterData(tableCreateFilter(e, name, val, filterData));
    },
    [filterData],
  );

  const nextPage = useCallback(() => {
    setPageData(tableCreatePage(pageData));
    setChangePage(true);
  }, [pageData]);

  const resetFilter = useCallback(() => {
    setFilterData(filterInit);
  }, [filterInit]);

  const refreshData = useCallback(() => {
    setFilterData({ ...filterData });
  }, [filterData]);

  return {
    listData,
    sortData,
    filterData,
    pageData,
    loading,
    loadingLoadMore: changePage,
    setSort,
    setFilter,
    nextPage,
    resetFilter,
    refreshData,
  };
};
