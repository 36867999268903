import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AUTH_ROUTES, AUTH_DEFAULT_ROUTE } from '../../common/constants/routes';
import { AuthLayout } from '../../common/layouts/AuthLayout';

import { UserListProvider } from '../../common/providers/UserListProvider';

export const AuthRoutes: React.FC = () => (
  <UserListProvider>
    <AuthLayout>
      <Switch>
        {AUTH_ROUTES.map((routeItem, index) => (
          <Route key={index} path={routeItem.path} exact={routeItem.exact} component={routeItem.component} />
        ))}
        <Redirect to={AUTH_DEFAULT_ROUTE} />
      </Switch>
    </AuthLayout>
  </UserListProvider>
);
