import React from 'react';
import moment from 'moment';
import { TableWrapper } from '../../../../common/components/TableWrapper/TableWrapper';
import { Table } from '../../../../common/components/Table/Table';
import { TableRow } from '../../../../common/components/TableRow/TableRow';
import { TableCell } from '../../../../common/components/TableCell/TableCell';
import { Icon } from '../../../../common/components/Icon/Icon';
import { Link } from '../../../../common/components/Link/Link';
import { Tag } from '../../../../common/components/Tag/Tag';
import { None } from '../../../../common/components/None/None';
import { AvatarName } from '../../../../common/components/AvatarName/AvatarName';
import { ReactComponent as NewWindowIcon } from '../../../../assets/images/icons/open_in_new.svg';
import { useTable } from '../../../../common/hooks/useTable';

import { URL_CONTRIBUTIONS, URL_PEOPLE } from '../../../../common/constants/url';

import { TableFilterContributionsList } from '../TableFilterContributionsList/TableFilterContributionsList';
import { IContributions, IContributionsListFilter } from '../../typings/contributions.type';
import { apiContributionsList } from '../../api/api-contributions-list';

const columnsList = [
  {
    title: 'type',
    sort: false,
    column: 'type',
  },
  {
    title: 'score',
    sort: false,
    column: 'score',
  },
  {
    title: 'title',
    sort: false,
    column: 'title',
  },
  {
    title: 'contributor',
    sort: false,
    column: 'name',
  },
  {
    title: 'category / tags',
    sort: false,
    column: 'tags',
  },
  {
    title: 'event date',
    sort: false,
    column: 'date',
  },
  {
    title: '',
    sort: false,
    column: '',
  },
];

export const TableContributionsList: React.FC = () => {
  const { listData, sortData, filterData, loading, loadingLoadMore, setSort, setFilter, resetFilter, nextPage } =
    useTable<IContributions, IContributionsListFilter>(apiContributionsList, {
      searchQuery: '',
      type: '',
      userId: '',
      dateFrom: '',
      dateTo: '',
    });
  return (
    <TableWrapper
      heading
      headingTitle={'Contributions'}
      headingInfoBlock={{ count: listData?.count || 0, all: listData?.totalCount || 0 }}
      headingBtnBlock={[{ link: `${URL_CONTRIBUTIONS}/add`, icon: 'plus', text: 'Add Contribution' }]}
      loadMoreShow={listData.data.length < listData?.count}
      loadMore={(e) => {
        e.preventDefault();
        nextPage();
      }}
      loading={loading}
      loadingLoadMore={loadingLoadMore}
    >
      <TableFilterContributionsList
        disabled={false}
        filterUpdate={setFilter}
        resetFilter={resetFilter}
        props={filterData}
      />
      <Table
        loading={loading}
        sortColumn={sortData.column}
        sortDir={sortData.dir}
        columns={columnsList}
        changeSort={setSort}
      >
        {listData &&
          listData.data.map((current, index) => (
            <TableRow key={index + '_' + current.id + current.title} type="border-bottom">
              <TableCell>
                <Icon name={current.type.id} />
                {current.type.id || <None />}
              </TableCell>
              <TableCell>{current.type.score || <None />}</TableCell>
              <TableCell style={{ width: '35%' }}>
                <Link type="ounter-url" target="_blank" to={`${current.url}`}>
                  {current.title || <None />}
                  <NewWindowIcon style={{ width: 16, marginLeft: 4 }} />
                </Link>
              </TableCell>
              <TableCell>
                {current.user && current.user.name && (
                  <AvatarName round image={current.user.avatarUrl}>
                    <Link type="black-url" to={`${URL_PEOPLE}/${current.user.id}`}>
                      {current.user.name}
                    </Link>
                  </AvatarName>
                )}
                {!current.user || (!current.user.name && <None />)}
              </TableCell>
              <TableCell>
                {(current.tags &&
                  current.tags.length !== 0 &&
                  current.tags.map((cat) => <Tag key={cat} title={cat} />)) || <None />}
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                {current.date ? moment(current.date).format('DD MMM, YYYY') : <None />}
              </TableCell>
              <TableCell>
                <Link to={`${URL_CONTRIBUTIONS}/${current.id}`} type="btn-round">
                  <Icon name="editing" />
                </Link>
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </TableWrapper>
  );
};
