import React from 'react';

import { URL_LOGIN } from '../../../common/constants/url';

import { removeItem } from '../../../common/utils/local-storage';
export const LogoutPage: React.FC = () => {
  removeItem('token');
  window.location.href = URL_LOGIN;
  return <div>LOGOUT</div>;
};
