import React from 'react';

import { View } from '../../../common/components/View/View';
import { TableContributionsList } from '../components/TableContributionsList/TableContributionsList';

export const ListContributionsPage: React.FC = () => (
  <View type="list">
    <TableContributionsList />
  </View>
);
