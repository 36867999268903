import React from 'react';
import { Menu } from 'antd';

import { Link } from '../Link/Link';
import { URL_LOGOUT } from '../../constants/url';

import s from './ProfileMenuOverlay.module.scss';

interface ProfileMenuOverlay {
  user: {
    name: string;
    email: string;
    id: string;
  };
}

export const ProfileMenuOverlay: React.FC<ProfileMenuOverlay> = ({ user }) => (
  <Menu style={{ zIndex: 9999 }}>
    <Menu.Item key="0" style={{ height: 'max-content' }}>
      <div className={s.name}>{user.name}</div>
      <div className={s.email}>{user.email}</div>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1">
      <Link to={URL_LOGOUT}>Log out</Link>
    </Menu.Item>
  </Menu>
);
