import React from 'react';
import { Input as AntInput, DatePicker } from 'antd';

import { Icon } from '../Icon/Icon';
import { AvatarSmall } from '../AvatarSmall/AvatarSmall';
import { Label } from '../Label/Label';
import { InputError } from '../InputError/InputError';
import { IInputDefault } from '../../typings/input.type';

import { isValidHttpUrl } from '../../utils/create-url';

import s from './Input.module.scss';
import moment from 'moment';

interface IInput extends IInputDefault {
  icon?: string;
  allowClear?: boolean;
  type?: 'default' | 'password' | 'date';
  value: string;
  label?: string;
  image?: string;
  error?: string;
  onChange?: (val: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDate?: (date: string | null) => void;
}

export const Input: React.FC<IInput> = ({
  allowClear = false,
  disabled,
  placeholder,
  icon,
  value,
  name,
  id,
  type = 'default',
  label,
  image,
  onChange,
  onChangeDate,
  required = false,
  error = '',
}) => {
  const WrapperInput = label ? Label : React.Fragment;
  const props = label ? { title: label, required } : {};
  return (
    <WrapperInput {...props}>
      {type == 'default' && (
        <AntInput
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className={s.input}
          disabled={disabled}
          allowClear={allowClear}
          placeholder={placeholder || ''}
          prefix={(image && isValidHttpUrl(image) && <AvatarSmall src={image} />) || (icon && <Icon name={icon} />)}
        />
      )}
      {type == 'password' && (
        <AntInput.Password
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className={s.input}
          disabled={disabled}
          allowClear={allowClear}
          placeholder={placeholder || ''}
          prefix={(image && isValidHttpUrl(image) && <AvatarSmall src={image} />) || (icon && <Icon name={icon} />)}
        />
      )}
      {type == 'date' && (
        <DatePicker
          value={value ? moment(value) : undefined}
          onChange={(date) => {
            if (onChangeDate) onChangeDate(date ? moment(date).format('YYYY-MM-DD') : null);
          }}
          style={{ width: '100%' }}
        />
      )}
      {error && <InputError text={error} />}
    </WrapperInput>
  );
};
