import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'antd';

import { useDebounce } from '../../../../common/hooks/useDebounced';

import { Input } from '../../../../common/components/Input/Input';
import { Link } from '../../../../common/components/Link/Link';
import { Icon } from '../../../../common/components/Icon/Icon';

import { IInputDefault } from '../../../../common/typings/input.type';
import { IDevs } from '../../typings/devs.type';

import { URL_PEOPLE } from '../../../../common/constants/url';

import { apiDevsList } from '../../api/api-devs-list';

import s from './InputDevs.module.scss';

interface IInputDevs extends IInputDefault {
  valueUser: IDevs | null;
  onChange: (e?: React.ChangeEvent<HTMLInputElement>, user?: IDevs) => void;
}

export const InputDevs: React.FC<IInputDevs> = ({ disabled = false, label, valueUser, placeholder, onChange }) => {
  const [serachValue, setSearchValue] = useState(
    valueUser ? `${valueUser.name} ${valueUser.email ? `(${valueUser.email})` : ''}` : '',
  );
  const debouncedSearch = useDebounce<string>(serachValue, 300);

  const [listData, setListData] = useState<Array<{ value: string; label: string }>>([]);
  const [listValueData, setListValueData] = useState<{ [key: string]: IDevs }>({});
  const [activeUser, setActiveUser] = useState<IDevs | null>(valueUser || null);

  useEffect(() => {
    const fetchList = async () => {
      if (debouncedSearch) {
        const list = await apiDevsList({ params: { searchQuery: debouncedSearch, limit: 10 } });
        const keyValTemp: { [key: string]: IDevs } = {};
        if (list) {
          setListData(
            list.data.map((user) => {
              keyValTemp[user.id] = user;
              return { value: user.id, label: `${user.name} ${user.email ? `(${user.email})` : ''}` };
            }),
          );
          setListValueData(keyValTemp);
        }
      } else {
        setListData([]);
        setActiveUser(null);
      }
    };

    fetchList();
  }, [debouncedSearch]);

  useEffect(() => {
    if (valueUser) {
      setActiveUser(valueUser);
      setSearchValue(`${valueUser.name} ${valueUser.email ? `(${valueUser.email})` : ''}`);
    }
  }, [valueUser]);

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      style={{ width: '100%' }}
      onSelect={(value: string) => {
        const user = listValueData[value];
        setActiveUser(user);
        setSearchValue(`${user.name} ${user.email ? `(${user.email})` : ''}`);
        onChange(undefined, user);
      }}
      options={listData}
    >
      <div className={s.wrapperInputDevs}>
        <Input
          value={serachValue}
          placeholder={placeholder}
          onChange={(e) => setSearchValue(e.target.value)}
          label={label}
          icon={'search'}
          disabled={disabled}
          image={activeUser ? activeUser.avatarUrl : undefined}
          allowClear={!!activeUser || false}
        />
        <Link disabled={disabled} type="ounter-bg-btn" to={`${URL_PEOPLE}/add`}>
          <Icon name="plus" /> Add
        </Link>
      </div>
    </AutoComplete>
  );
};
