import React from 'react';

import { numberFormat } from '../../../common/utils/number-format';

import s from './TableFoundCount.module.scss';

interface ITableFoundCountProps {
  count: number;
  all: number;
}

export const TableFoundCount: React.FC<ITableFoundCountProps> = ({ count, all }) => (
  <div>
    <span className={s.foundCount}>{numberFormat(count)}</span>&nbsp;found&nbsp;
    <span className={s.foundAll}>from&nbsp;{numberFormat(all)}</span>
  </div>
);
