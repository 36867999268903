import React from 'react';

import s from './Icon.module.scss';

import { ReactComponent as SettingsIcon } from '../../../assets/images/icons/settings.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/images/icons/people.svg';
import { ReactComponent as ContributionsIcon } from '../../../assets/images/icons/contributions.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/images/icons/profile.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/icons/arrow-down.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/icons/search.svg';
import { ReactComponent as ArrowLongDownIcon } from '../../../assets/images/icons/arrow-long-down.svg';
import { ReactComponent as ArrowLongUpIcon } from '../../../assets/images/icons/arrow-long-up.svg';
import { ReactComponent as ArrowLongLeftIcon } from '../../../assets/images/icons/arrow-long-left.svg';
import { ReactComponent as PointsIcon } from '../../../assets/images/icons/points.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg';
import { ReactComponent as NewWindowIcon } from '../../../assets/images/icons/open_in_new.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/icons/mail.svg';

interface IconProps {
  name: string;
}

export const Icon: React.FC<IconProps> = ({ name }) => (
  <span className={s.iconWrapper}>
    {name === 'settings' && <SettingsIcon />}
    {name === 'people' && <PeopleIcon />}
    {name === 'contributions' && <ContributionsIcon />}
    {name === 'profile' && <ProfileIcon />}
    {name === 'arrow-down' && <ArrowDownIcon />}
    {name === 'plus' && <PlusIcon />}
    {name === 'search' && <SearchIcon />}
    {name === 'arrow-long-down' && <ArrowLongDownIcon />}
    {name === 'arrow-long-up' && <ArrowLongUpIcon />}
    {name === 'arrow-long-left' && <ArrowLongLeftIcon />}
    {name === 'points' && <PointsIcon />}
    {name === 'trash' && <TrashIcon />}
    {name === 'editing' && <EditIcon />}
    {name === 'new-window' && <NewWindowIcon />}
    {name === 'mail' && <MailIcon />}
  </span>
);
