import React, { useEffect } from 'react';
import { Dropdown } from 'antd';
import { Icon } from '../Icon/Icon';
import { ProfileMenuOverlay } from '../ProfileMenuOverlay/ProfileMenuOverlay';

import s from './ProfileMenu.module.scss';
import { apiGetDevMe } from '../../../features/devs/api/api-get-me';
import { IDevs } from '../../../features/devs/typings/devs.type';

export const ProfileMenu: React.FC = () => {
  const [user, setUser] = React.useState<null | IDevs>(null);
  useEffect(() => {
    apiGetDevMe({}).then((user) => {
      setUser(user);
    });
  }, []);
  return (
    <div className={s.profileMenuWrapper}>
      {user && (
        <Dropdown className={s.nameUserLink} overlay={<ProfileMenuOverlay user={user} />}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Icon name="profile" />
            <span className={s.nameUser}>{user.name}</span>
            <span className={s.nameUser}>
              <Icon name="arrow-down" />
            </span>
          </a>
        </Dropdown>
      )}
    </div>
  );
};
