import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_LIST_CONTRIBUTIONS } from '../../../common/constants/api';

import { IContributionsList } from '../typings/contributions.type';
import { getProduct } from '../../../common/utils/local-storage';

export const apiContributionsList = async ({ params = {} }: IApiRequest): Promise<IContributionsList | null> =>
  // FIXME: Добавить функционал продукта
  await httpGET<IContributionsList>({
    url: API_LIST_CONTRIBUTIONS,
    params: Object.assign(params, { product: getProduct() }),
  });
