import React from 'react';
import { TableWrapper } from '../../../../common/components/TableWrapper/TableWrapper';
import { Table } from '../../../../common/components/Table/Table';
import { TableRow } from '../../../../common/components/TableRow/TableRow';
import { TableCell } from '../../../../common/components/TableCell/TableCell';
import { Icon } from '../../../../common/components/Icon/Icon';
import { Link } from '../../../../common/components/Link/Link';
import { useTable } from '../../../../common/hooks/useTable';

import { URL_PRODUCTS } from '../../../../common/constants/url';
import { IProduct, IProductListFilter } from '../../typings/products.type';
import { apiProductList } from '../../api/api-product-list';

const columnList = [
  {
    title: 'id/slug',
    sort: true,
    column: 'id',
  },
  {
    title: 'display name',
    sort: true,
    column: 'name',
  },
  {
    title: 'description',
    sort: false,
    column: 'description',
  },
  {
    title: 'private',
    sort: false,
    column: 'private',
  },
  {
    title: 'Stack Overflow Tags',
    sort: false,
    column: 'stackOverflowTags',
  },
  {
    title: 'Apache User Lists',
    sort: false,
    column: 'apacheLists',
  },
];

export const TableProductList: React.FC = () => {
  const { listData, sortData, filterData, loading, loadingLoadMore, setSort, setFilter, resetFilter, nextPage } =
    useTable<IProduct, IProductListFilter>(apiProductList, {
      searchQuery: '',
      includePublic: true,
      includePrivate: true,
    });

  return (
    <TableWrapper
      heading
      headingTitle={'Products'}
      headingBtnBlock={[{ link: `${URL_PRODUCTS}/add`, icon: 'plus', text: 'Add Product' }]}
      loading={loading}
      loadingLoadMore={loadingLoadMore}
    >
      <Table
        loading={loading}
        sortColumn={sortData.column}
        sortDir={sortData.dir}
        columns={columnList}
        changeSort={setSort}
      >
        {listData?.data?.map((current, index) => (
          <TableRow key={index + '_' + current.id + current.name} type="border-bottom">
            <TableCell style={{ width: '10%' }}>{current.id}</TableCell>
            <TableCell style={{ width: '12%' }}>{current.name}</TableCell>
            <TableCell style={{ width: '35%' }}>
              {current.description}
              <br/>
              <a href={current.link} target="_blank"
              style={{color: "black", textDecoration: "underline"}}>{current.link}</a>
            </TableCell>
            <TableCell>{current.private ? 'Yes' : 'No'}</TableCell>
            <TableCell>{current.stackOverflowTags}</TableCell>
            <TableCell>{current.apacheLists}</TableCell>
            <TableCell>
              <Link to={`${URL_PRODUCTS}/${current.id}`} type="btn-round">
                <Icon name="editing" />
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TableWrapper>
  );
};
