import React from 'react';

import { View } from '../../../common/components/View/View';
import { TableProductList } from '../components/TableProductList/TableProductList';

export const ListProductsPage: React.FC = () => (
  <View type="list">
    <TableProductList />
  </View>
);
