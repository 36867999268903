import React from 'react';

import s from './Table.module.scss';

import { TableHead } from '../TableHead/TableHead';
import { TableBody } from '../TableBody/TableBody';
import { TableRow } from '../TableRow/TableRow';
import { TableCell } from '../TableCell/TableCell';

import { Spinner } from '../Spinner/Spinner';

interface ITable {
  children?: React.ReactNode;
  sortColumn: string | null;
  sortDir: 'DESC' | 'ASC' | null;
  columns: ITableColumn[];
  loading?: boolean;
  changeSort: (sortData: { column: string | null; dir: 'DESC' | 'ASC' }) => void;
}

interface ITableColumn {
  title: string;
  sort: boolean | null;
  column: string | null;
}

export const Table: React.FC<ITable> = ({ loading = false, children, sortColumn, sortDir, columns, changeSort }) => (
  <React.Fragment>
    {!loading && (
      <table className={s.table}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  if (!column.sort) return;
                  changeSort({
                    column: column.column,
                    dir: sortColumn === column.column ? (sortDir === 'ASC' ? 'DESC' : 'ASC') : 'DESC',
                  });
                }}
                type="th"
                sortActive={sortColumn === column.column}
                sortDir={sortDir}
                disabled={!column.sort}
                key={column.title}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </table>
    )}
    {loading && <Spinner />}
  </React.Fragment>
);
