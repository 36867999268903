import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { TableFilterWrapper } from '../../../../common/components/TableFilterWrapper/TableFilterWrapper';
import { Input } from '../../../../common/components/Input/Input';
import { Link } from '../../../../common/components/Link/Link';
// import { Select } from '../../../../common/components/Select/Select';
import { RangeDate } from '../../../../common/components/RangeDate/RangeDate';
// import { MultiSelect } from '../../../../common/components/MultiSelect/MultiSelect';

import { IContributionsListFilterProps, IContributionType } from '../../typings/contributions.type';
import { Select } from '../../../../common/components/Select/Select';
import { apiContributionsTypes } from '../../api/api-contributions-types';
import { getProduct } from '../../../../common/utils/local-storage';
import { apiProductList } from 'src/features/products/api/api-product-list';
import { IProduct } from 'src/features/products/typings/products.type';

export const TableFilterContributionsList: React.FC<IContributionsListFilterProps> = ({
  disabled,
  props,
  filterUpdate,
  resetFilter,
}) => {
  const [products, setProducts] = useState<Array<{ value: string; label: string }>>([]);
  const [types, setTypes] = useState<Array<{ value: string; label: string }>>([]);

  // FIXME: упаковать в hook и убрать в mobx
  useEffect(() => {
    const getProducts = async () => {
      const products = await apiProductList({});
      if (products) {
        setProducts(
          products.data.map((product: IProduct) => ({
            value: product.id,
            label: product.name,
          })),
        );
      }
    };
    getProducts();
  }, []);

  useEffect(() => {
    const getTypes = async () => {
      const typesData = await apiContributionsTypes({});
      if (typesData) {
        setTypes(typesData.map((type: IContributionType) => ({ value: type.id, label: type.name })));
      }
    };
    getTypes();
  }, []);

  return (
    <TableFilterWrapper>
      <div>
        <Input
          value={props.searchQuery}
          placeholder={'Search by title'}
          name="searchQuery"
          onChange={(event) => {
            filterUpdate(null, 'searchQuery', event.target.value);
          }}
          icon={'search'}
          disabled={disabled}
          allowClear
        />
      </div>
      <div>
        <Select
          value={props.type}
          placeholder={'Any type'}
          options={types}
          allowClear
          onChange={(val) => {
            filterUpdate(null, 'type', val);
          }}
        />
      </div>
      <div>
        <RangeDate
          //FIXME: запихнуть в конвертор
          disabled={disabled}
          value={
            props.dateFrom
              ? `${moment(props.dateFrom).format('YYYY-MM-DD')}/${moment(props.dateTo).format('YYYY-MM-DD')}`
              : ''
          }
          onChange={(val) => {
            //FIXME: запихнуть в конвертор
            if (val) {
              const dates = val.split('/');
              filterUpdate(null, '', {
                dateFrom: moment(dates[0], 'YYYY-MM-DD').format('YYYY-MM-DD'),
                dateTo: moment(dates[1], 'YYYY-MM-DD').format('YYYY-MM-DD'),
              });
            } else {
              filterUpdate(null, '', {
                dateFrom: '',
                dateTo: '',
              });
            }
          }}
        />
      </div>
      {/* <div>
      <MultiSelect
        value={filterState.category}
        placeholder={'Category'}
        onChange={(val) => {
          filterUpdate(null, 'category', val);
        }}
      />
    </div> */}
      {(props.searchQuery !== '' || props.dateFrom !== '') && (
        <div className={'reset-center'}>
          <Link
            to="#"
            type="reset-filter"
            onClick={(e) => {
              e.preventDefault();
              resetFilter();
            }}
          >
            Reset filter
          </Link>
        </div>
      )}
    </TableFilterWrapper>
  );
};
