import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { Form } from '../../../../common/components/Form/Form';
import { FormRow } from '../../../../common/components/FormRow/FormRow';
import { FormCell } from '../../../../common/components/FormCell/FormCell';
import { Input } from '../../../../common/components/Input/Input';
import { Link } from '../../../../common/components/Link/Link';

import { IDevs, IDevsEdit, IDevsFormError } from '../../typings/devs.type';

import { apiAddDevs } from '../../api/api-add-dev';
import { apiGetDev } from '../../api/api-get-dev';
import { apiEditDev } from '../../api/api-edit-dev';
import { apiRemoveDev } from '../../api/api-remove-dev';

import { openNotification } from '../../../../common/utils/notification';

import { URL_PEOPLE } from '../../../../common/constants/url';

import { Popconfirm } from 'antd';

export const EditDevsForm: React.FC = () => {
  const params = useParams<{ [key: string]: string }>();
  const history = useHistory();
  const location = useLocation();

  const qs = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [title, setTitle] = useState<string>('');
  //FIXME: Убрать в хук
  const [loading, setLoading] = useState({ edit: true, remove: false });
  const [loadingData, setLoadingData] = useState(true);

  const [formValue, setFormValue] = useState<IDevsEdit>({
    id: '',
    avatarUrl: '',
    email: qs.get('email') || '',
    name: '',
    github: '',
    stackoverflow: '',
  });

  const [formValueError, setFormValueError] = useState<IDevsFormError>({
    avatarUrl: '',
    email: '',
    name: '',
    github: '',
    stackoverflow: '',
  });

  useEffect(() => {
    const getUser = async () => {
      const user = await apiGetDev({ options: { id: params.prefix } });
      if (user) {
        setFormValue(user);
        setLoading({ edit: false, remove: false });
        setLoadingData(false);
        setTitle(user.name);
      }
    };

    if (params.prefix === 'add') {
      setTitle('New contributor');
      setLoading({ edit: false, remove: false });
    } else {
      getUser();
    }
  }, [params.prefix]);

  const formChangeHandler = (e?: React.ChangeEvent<HTMLInputElement>, user?: IDevs): void => {
    if (e) {
      setFormValueError(Object.assign({}, formValueError, { [e.target.name]: '' }));
      return setFormValue(Object.assign({}, formValue, { [e.target.name]: e.target.value }));
    }

    if (user) {
      return setFormValue(Object.assign({}, formValue, { user: user }));
    }
  };

  //FIXME: унести в логику формы и сделать валидацию в логике формы
  const handlerSubmitForm = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();
    setLoading({ edit: true, remove: false });
    if (!formValue.name.trim()) {
      return setFormValueError(Object.assign({}, formValueError, { name: 'name is required' }));
    }

    console.log('formValue.stackoverflow', formValue.stackoverflow);
    const formValueTRIM: IDevsEdit = {
      id: formValue.id,
      avatarUrl: formValue.avatarUrl?.trim(),
      email: formValue.email?.trim(),
      name: formValue.name?.trim(),
      github: formValue.github?.trim(),
      stackoverflow: formValue.stackoverflow?.toString().trim(),
    };

    let status = null;
    if (params.prefix === 'add') {
      const body = Object.assign({}, formValueTRIM, { email: formValue.email || undefined });
      status = await apiAddDevs({ body: { ...body } });
    } else if (formValue.id) {
      status = await apiEditDev({ body: { ...formValueTRIM }, options: { id: formValue.id } });
    }

    setLoading({ edit: false, remove: false });
    if (status && status.id) {
      // FIXME: убрать в константы
      openNotification({
        type: 'success',
        message: '',
        description: `${formValue.name} has been ${params.prefix === 'add' ? 'created' : 'updated'}`,
      });
      return history.push(URL_PEOPLE);
    }
  };

  const handlerRemoveUser = async (): Promise<void> => {
    setLoading({ edit: false, remove: true });
    if (formValue.id) {
      const status = await apiRemoveDev({ options: { id: formValue.id } });
      if (status && status.id) {
        // FIXME: убрать в константы
        openNotification({
          type: 'success',
          message: '',
          description: `${formValue.name} has been removed`,
        });
        setLoading({ edit: false, remove: false });
        return history.push(URL_PEOPLE);
      }
    }
  };

  return (
    <Form loading={params.prefix !== 'add' ? loadingData : false} title={title} backArrow>
      <FormRow>
        <FormCell>
          <Input
            label={'Display name'}
            name="name"
            placeholder={'Display name'}
            value={formValue.name}
            required
            onChange={formChangeHandler}
            error={formValueError.name}
            allowClear
          />
        </FormCell>
        <FormCell>
          <Input
            image={formValue.avatarUrl}
            label={'Avatar URL'}
            name="avatarUrl"
            placeholder={'Avatar URL'}
            value={formValue.avatarUrl}
            onChange={formChangeHandler}
            allowClear
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <Input
            label={'EMAIL'}
            name="email"
            placeholder={'EMAIL'}
            value={formValue.email}
            onChange={formChangeHandler}
            allowClear
          />
        </FormCell>
        <FormCell>
          <Input
            label={'Github'}
            name="github"
            placeholder={'Github profile name'}
            value={formValue.github}
            onChange={formChangeHandler}
            allowClear
          />
        </FormCell>
        <FormCell>
          <Input
            label={'Stackoverflow User ID'}
            name="stackoverflow"
            placeholder={'Stackoverflow User ID'}
            value={formValue.stackoverflow}
            onChange={formChangeHandler}
            allowClear
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <Link
            type="ant-form-save"
            disabled={!formValue.name}
            loading={loading.edit}
            to={'#'}
            onClick={handlerSubmitForm}
          >
            {params.prefix === 'add' ? 'Create user' : 'Update user'}
          </Link>
          {params.prefix !== 'add' && (
            <Popconfirm
              placement="left"
              title={'Are you sure want to delete this user?'}
              onConfirm={(e) => {
                e?.preventDefault();
                handlerRemoveUser();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Link type="ant-form-archive" loading={loading.remove} to={'#'}>
                Delete user
              </Link>
            </Popconfirm>
          )}
        </FormCell>
      </FormRow>
    </Form>
  );
};
