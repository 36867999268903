import React from 'react';

import { View } from '../../../common/components/View/View';

import { EditDevsForm } from '../components/EditDevsForm/EditDevsForm';

export const EditDevsPage: React.FC = () => (
  <View type="form">
    <EditDevsForm />
  </View>
);
