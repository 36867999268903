import React from 'react';

import { LogoImage } from '../LogoImage/LogoImage';

import s from './LogoWrapper.module.scss';

export const LogoWrapper: React.FC = () => (
  <div className={s.logoWrapper}>
    <div className={s.logoImageWrapper}>
      <LogoImage />
    </div>
    <div className={s.logoTitle}>
      <div className={s.logoTitleUp}>Apache Ignite</div>
      <div className={s.logoTitleDown}>Recognition System</div>
    </div>
  </div>
);