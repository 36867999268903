import React from 'react';

import { AvatarSmall } from '../AvatarSmall/AvatarSmall';
import { Icon } from '../Icon/Icon';

import s from './AvatarName.module.scss';

interface IAvatarName {
  image?: string;
  round?: boolean;
  children?: React.ReactNode;
}

export const AvatarName: React.FC<IAvatarName> = ({ image, round = false, children }) => (
  <div className={s.avatarNameWrapper}>
    {image && <AvatarSmall src={image} round={round} />}
    {!image && <Icon name="profile" />}
    {children}
  </div>
);
