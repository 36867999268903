import React, { useContext, useState } from 'react';
import { FormRow } from '../../../../common/components/FormRow/FormRow';
import { Form } from '../../../../common/components/Form/Form';
import { FormCell } from '../../../../common/components/FormCell/FormCell';
import { Input } from '../../../../common/components/Input/Input';
import { Link } from '../../../../common/components/Link/Link';
import { ILoginCredentials, ILoginCredentialsFormError } from '../../typings/login-creds.type';
import { apiLogin } from '../../api/login';
import { authContext } from 'src/common/contexts/authContext';

export const LoginForm: React.FC = () => {
  const { setToken } = useContext(authContext);

  const [loading, setLoadinfg] = useState(false);

  const [formValue, setFormValue] = useState<ILoginCredentials>({
    email: '',
    password: '',
  });

  const [formValueError, setFormValueError] = useState<ILoginCredentialsFormError>({
    email: '',
    password: '',
  });

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormValueError({ ...formValueError, [e.target.name]: '' });
    setFormValue({ ...formValue, [e.target.name]: e.target.value.trim() });
  };

  const handleFormSubmit = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();

    if (!formValue.email.trim()) {
      setFormValueError(Object.assign({}, formValueError, { email: 'Email is required' }));
      return;
    }

    if (!formValue.password.trim()) {
      setFormValueError(Object.assign({}, formValueError, { password: 'Password is required' }));
      return;
    }

    const loginCreds: ILoginCredentials = {
      email: formValue.email,
      password: formValue.password,
    };

    setLoadinfg(true);
    const result = await apiLogin({ body: { ...loginCreds } });
    setLoadinfg(false);

    if (result?.token) {
      setToken(result.token);
    }
  };

  return (
    <Form loading={loading} title="Login" backArrow={false}>
      <FormRow>
        <FormCell>
          <Input
            label="Email"
            name="email"
            placeholder="Email"
            value={formValue.email}
            required
            onChange={handleFormChange}
            error={formValueError.email}
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <Input
            type="password"
            label="Password"
            name="password"
            value={formValue.password}
            required
            onChange={handleFormChange}
            error={formValueError.password}
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <Link
            type="ant-form-save"
            disabled={!formValue.email || !formValue.password}
            to={'#'}
            onClick={handleFormSubmit}
          >
            Login
          </Link>
        </FormCell>
      </FormRow>
    </Form>
  );
};
