import { IObject } from '../typings/object.type';

export const TOKEN_KEY = 'token';
export const PRODUCT_KEY = 'product';

export const getItem = (name: string): string | null | IObject => {
  const data = localStorage.getItem(name);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (err) {
      if (typeof data === 'string') {
        return data;
      }
    }
  }

  return null;
};

export const getProduct = (): string | null => localStorage.getItem(PRODUCT_KEY) || null;

export const setItem = (name: string, data: IObject | string): void => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const removeItem = (name: string): void => {
  localStorage.removeItem(name);
};
