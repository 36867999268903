import React from 'react';
import { Select as AntSelect } from 'antd';

import { Label } from '../Label/Label';

import { IInputDefault } from '../../typings/input.type';

import s from './Select.module.scss';

interface ISelect extends IInputDefault {
  value: string;
  options?: Array<{ value: string; label: string }>;
  onChange: (val: string) => void;
  allowClear?: boolean;
  // api
}

export const Select: React.FC<ISelect> = ({
  disabled,
  allowClear,
  label,
  placeholder,
  value,
  id,
  onChange,
  options,
}) => {
  // api

  const WrapperInput = label ? Label : React.Fragment;
  const props = label ? { title: label } : {};
  return (
    <WrapperInput {...props}>
      <AntSelect
        allowClear={allowClear}
        disabled={disabled}
        className={s.select}
        placeholder={placeholder}
        value={value || undefined}
        id={id}
        onChange={onChange}
      >
        {options &&
          options.map((option) => (
            <AntSelect.Option key={option.value} value={option.value}>
              {option.label}
            </AntSelect.Option>
          ))}
      </AntSelect>
    </WrapperInput>
  );
};
