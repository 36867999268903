import React from 'react';
import { MainContentGuest } from '../components/MainContentGuest/MainContentGuest';

import { ILayout } from '../typings/layout.type';

export const GuestLayout: React.FC = ({ children }: ILayout) => (
  <>
    <MainContentGuest>{children}</MainContentGuest>
  </>
);
