import React from 'react';

import { FormHeading } from '../FormHeading/FormHeading';

interface IForm {
  children?: React.ReactNode;
  title: string;
  backArrow: boolean;
  loading?: boolean;
}

export const Form: React.FC<IForm> = ({ loading = false, children, title, backArrow }) => (
  <form>
    <FormHeading loading={loading} title={title} backArrow={backArrow} />
    <div>{children}</div>
  </form>
);
