import React from 'react';
import classnames from 'classnames';

import s from './TableRow.module.scss';

interface ITableRow {
  children?: React.ReactNode;
  type?: 'border-bottom';
}

export const TableRow: React.FC<ITableRow> = ({ children, type }) => (
  <tr className={classnames({ [s.borderBottom]: type === 'border-bottom' })}>{children}</tr>
);
