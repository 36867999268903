import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { useUserList } from '../../providers/UserListProvider';

import { Link } from '../Link/Link';
import { Icon } from '../Icon/Icon';
import { URL_CONTRIBUTIONS, URL_PEOPLE, URL_MAILING_LIST, URL_PRODUCTS } from '../../constants/url';

import s from './Menu.module.scss';

export const Menu: React.FC = () => {
  const location = useLocation();
  const { notModeratedCount, refreshNotModeratedCount } = useUserList();

  React.useEffect(() => {
    void refreshNotModeratedCount();
  }, [refreshNotModeratedCount]);

  return (
    <div className={s.menuWrapper}>
      <ul className={s.menuList}>
        <li className={s.menuListItem}>
          <Link
            className={classnames(s.menuLink, { [s.menuLinkActive]: location.pathname === URL_CONTRIBUTIONS })}
            to={URL_CONTRIBUTIONS}
          >
            <Icon name="contributions" /> Contributions
          </Link>
        </li>

        <li className={s.menuListItem}>
          <Link
            className={classnames(s.menuLink, { [s.menuLinkActive]: location.pathname === URL_PEOPLE })}
            to={URL_PEOPLE}
          >
            <Icon name="people" /> People
          </Link>
        </li>

        <li className={s.menuListItem}>
          <Link
            className={classnames(s.menuLink, { [s.menuLinkActive]: location.pathname === URL_MAILING_LIST })}
            to={URL_MAILING_LIST}
          >
            <Icon name="mail" /> User list {notModeratedCount ? `(${notModeratedCount} new)` : ''}
          </Link>
        </li>
      </ul>
    </div>
  );
};
