import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Popconfirm } from 'antd';

import { Form } from '../../../../common/components/Form/Form';
import { FormRow } from '../../../../common/components/FormRow/FormRow';
import { FormCell } from '../../../../common/components/FormCell/FormCell';
import { Input } from '../../../../common/components/Input/Input';
import { Select } from '../../../../common/components/Select/Select';
import { Link } from '../../../../common/components/Link/Link';
import { MultiSelect } from '../../../../common/components/MultiSelect/MultiSelect';

import { InputDevs } from '../../../devs/components/InputDevs/InputDevs';

import {
  IContributionsEdit,
  IContributionsFormError,
  IContributionType,
  IContributionTags,
} from '../../typings/contributions.type';

import { IDevs } from '../../../devs/typings/devs.type';

import { apiGetContribution } from '../../api/api-get-contribution';
import { apiAddContribution } from '../../api/api-add-contribution';
import { apiEditContribution } from '../../api/api-edit-contribution';
import { apiRemoveContribution } from '../../api/api-remove-contributions';
import { apiContributionsTypes } from '../../api/api-contributions-types';
import { apiContributionsTags } from '../../api/api-contributions-tags';

import { URL_CONTRIBUTIONS } from '../../../../common/constants/url';

import { openNotification } from '../../../../common/utils/notification';
import { getProduct } from '../../../../common/utils/local-storage';
import { apiProductList } from 'src/features/products/api/api-product-list';
import { IProduct } from 'src/features/products/typings/products.type';

export const EditContributionsForm: React.FC = () => {
  const params = useParams<{ [key: string]: string }>();
  const history = useHistory();

  const [title, setTitle] = useState<string>('');
  const [products, setProducts] = useState<Array<{ value: string; label: string }>>([]);
  const [types, setTypes] = useState<Array<{ value: string; label: string }>>([]);
  const [tags, setTags] = useState<Array<{ value: string; label: string }>>([]);
  const [loading, setLoading] = useState({ edit: true, remove: false });
  const [loadingData, setLoadingData] = useState(true);

  const currentProduct = getProduct();

  const [formValue, setFormValue] = useState<IContributionsEdit>({
    user: null,
    title: '',
    type: '',
    url: '',
    date: '',
    tags: [],
    products: ['ignite'],
  });

  const [formValueError, setFormValueError] = useState<IContributionsFormError>({
    user: '',
    title: '',
    type: '',
    url: '',
    date: '',
    tags: '',
    products: '',
  });

  useEffect(() => {
    const getContribution = async () => {
      const contribution = await apiGetContribution({ options: { id: params.prefix } });
      if (contribution) {
        setFormValue({
          id: contribution.id?.toString(),
          user: contribution.user,
          title: contribution.title,
          type: contribution.type?.name,
          url: contribution.url,
          date: contribution.date,
          tags: contribution.tags || [],
          products: contribution.products ? contribution.products.map((item) => item.id) : [],
        });
        setLoading({ edit: false, remove: false });
        setTitle(contribution.title);
        setLoadingData(false);
      }
    };

    if (params.prefix === 'add') {
      setTitle('New Contribution');
      setLoading({ edit: false, remove: false });
    } else {
      getContribution();
    }
  }, [params.prefix]);

  // FIXME: упаковать в hook и убрать в mobx
  useEffect(() => {
    const getProducts = async () => {
      const products = await apiProductList({});
      if (products) {
        setProducts(
          products.data.map((product: IProduct) => ({
            value: product.id,
            label: product.name,
          })),
        );
      }
    };
    getProducts();
  }, []);

  // FIXME: упаковать в hook и убрать в mobx
  useEffect(() => {
    const getTypes = async () => {
      const typesData = await apiContributionsTypes({});
      if (typesData) {
        setTypes(
          typesData.map((type: IContributionType) => ({
            value: type.id,
            label: `${type.name} (score: ${type.score})`,
          })),
        );
      }
    };
    getTypes();
  }, []);

  // FIXME: упаковать в hook и убрать в mobx
  useEffect(() => {
    const getTags = async () => {
      const tagsData = await apiContributionsTags({});
      if (tagsData) {
        setTags(tagsData.map((type: IContributionTags) => ({ value: type.value, label: type.value })));
      }
    };
    getTags();
  }, []);

  const formChangeHandler = (e?: React.ChangeEvent<HTMLInputElement>, user?: IDevs): void => {
    if (e) {
      setFormValueError(Object.assign({}, formValueError, { [e.target.name]: '' }));
      return setFormValue(Object.assign({}, formValue, { [e.target.name]: e.target.value }));
    }

    if (user) {
      return setFormValue(Object.assign({}, formValue, { user: user }));
    }
  };

  //FIXME: унести в логику формы и сделать валидацию в логике формы
  const handlerSubmitForm = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();
    setLoading({ edit: true, remove: false });
    if (!formValue.user) {
      // FIXME: убрать в константы
      return setFormValueError(Object.assign({}, formValueError, { user: 'user is required' }));
    }

    if (!formValue.title) {
      // FIXME: убрать в константы
      return setFormValueError(Object.assign({}, formValueError, { email: 'title is required' }));
    }

    let status = null;
    const body = {
      title: formValue.title,
      type: formValue.type,
      url: formValue.url,
      date: moment(formValue.date).format('yyyy-MM-DD'),
      tags: formValue.tags,
      products: ['ignite'],
    };

    if (params.prefix === 'add') {
      status = await apiAddContribution({
        options: { id: formValue.user.id },
        body,
      });
    } else if (formValue.id) {
      status = await apiEditContribution({ body, options: { id: formValue.id } });
    }

    setLoading({ edit: false, remove: false });
    if (status && status.id) {
      // FIXME: убрать в константы
      openNotification({
        type: 'success',
        message: '',
        description: `Contribution has been ${params.prefix === 'add' ? 'created' : 'updated'}`,
      });
      return history.push(URL_CONTRIBUTIONS);
    }
  };

  const handlerRemoveContribution = async (): Promise<void> => {
    setLoading({ edit: false, remove: true });
    if (formValue.id) {
      const status = await apiRemoveContribution({ options: { id: formValue.id } });
      if (status && status.id) {
        // FIXME: убрать в константы
        openNotification({
          type: 'success',
          message: 'Info',
          description: `Contribution has been remove`,
        });
        setLoading({ edit: false, remove: false });
        return history.push(URL_CONTRIBUTIONS);
      }
    }
  };

  return (
    <Form title={title} loading={params.prefix !== 'add' ? loadingData : false} backArrow>
      <FormRow>
        <FormCell>
          <InputDevs
            valueUser={formValue.user}
            onChange={formChangeHandler}
            label={'Contributor'}
            disabled={params.prefix !== 'add'}
            placeholder={'Search by name / email'}
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <Select
            label={'Event Type'}
            placeholder={'Event Type'}
            value={formValue.type}
            allowClear
            options={types}
            onChange={(type) => {
              setFormValue(Object.assign({}, formValue, { type: type }));
            }}
          />
        </FormCell>
        <FormCell>
          <Input
            type="date"
            label={'Event Date'}
            placeholder={'Event Date'}
            value={formValue.date}
            allowClear
            onChangeDate={(date) => {
              setFormValue(Object.assign({}, formValue, { date: date }));
            }}
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <Input
            label={'Title'}
            name="title"
            allowClear
            placeholder={'Title'}
            value={formValue.title}
            onChange={formChangeHandler}
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <Input
            allowClear
            label={'Link'}
            name="url"
            placeholder={'Link'}
            value={formValue.url}
            onChange={formChangeHandler}
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <MultiSelect
            label={'CATEGORIES, TAGS'}
            placeholder={'CATEGORIES, TAGS'}
            value={formValue.tags}
            onChange={(tags) => {
              setFormValue(Object.assign({}, formValue, { tags: tags }));
            }}
            options={tags}
          />
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <Link
            type="ant-form-save"
            disabled={!formValue.title || !formValue.date || !formValue.type || !formValue.url || !formValue.user}
            loading={loading.edit}
            to={'#'}
            onClick={handlerSubmitForm}
          >
            {params.prefix === 'add' ? 'Create contribution' : 'Update contribution'}
          </Link>
          {params.prefix !== 'add' && (
            <Popconfirm
              placement="left"
              title={'Are you sure want to delete this contribution?'}
              onConfirm={(e) => {
                e?.preventDefault();
                handlerRemoveContribution();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Link type="ant-form-archive" loading={loading.remove} to={'#'}>
                Delete contribution
              </Link>
            </Popconfirm>
          )}
        </FormCell>
      </FormRow>
    </Form>
  );
};
