import React from 'react';
import classnames from 'classnames';

import s from './AvatarSmall.module.scss';

export const AvatarSmall: React.FC<{ src: string; round?: boolean }> = ({ src, round = false }) => (
  <span>
    <img className={classnames(s.avatarSmall, { [s.avatarRound]: round })} src={src} alt="" />
  </span>
);
