export const API_LOGIN = '/users/login';
export const API_LIST_USERS = '/users/';
export const API_ADD_USER = '/users/';
export const API_EDIT_USER = '/users/:id';
export const API_GET_USER = '/users/:id';
export const API_REMOVE_USER = '/users/:id';
export const API_GET_USER_ME = '/users/me';

export const API_LIST_CONTRIBUTIONS = '/contributions/';
export const API_ADD_CONTRIBUTION = '/users/:id/contributions/';
export const API_EDIT_CONTRIBUTION = '/contributions/:id';
export const API_GET_CONTRIBUTION = '/contributions/:id';
export const API_REMOVE_CONTRIBUTIONS = '/contributions/:id';

export const API_LIST_PRODUCTS = '/products/';
export const API_ADD_PRODUCT = '/products/';
export const API_EDIT_PRODUCT = '/products/:id';
export const API_GET_PRODUCT = '/products/:id';
export const API_REMOVE_PRODUCT = '/products/:id';

export const API_LIST_MAILINGS = '/userlist/';
export const API_IGNORE_MAILING = '/userlist/:messageId/ignore';
export const API_PUBLISH_MAILING = '/userlist/:messageId/publish';
export const API_MAILINGS_NOT_MODERATED_COUNT = '/userlist/count-not-moderated';

export const API_LIST_CONTRIBUTIONS_TAGS = '/stats/groupby/tag';
export const API_LIST_CONTRIBUTIONS_TYPES = '/contributions/types';
