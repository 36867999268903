import { useState } from 'react';

export const useLocalStorage = (key: string, initialValue?: string): [string | undefined, (value: string) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const existingValue = window.localStorage.getItem(key);
      return existingValue ?? initialValue;
    } catch (error) {
      console.log(`Error reading value from localStorage for key "${key}"`, error);
      return initialValue;
    }
  });

  return [
    storedValue,
    (value: string): void => {
      setStoredValue(value);
      try {
        window.localStorage.setItem(key, value);
      } catch (error) {
        console.log(`Error writing value to localStorage for key "${key}"`, error);
      }
    },
  ];
};
