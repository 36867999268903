import React from 'react';

import { View } from '../../../common/components/View/View';
import { TableDevsList } from '../components/TableDevsList/TableDevsList';

export const ListDevsPage: React.FC = () => (
  <View type="list">
    <TableDevsList />
  </View>
);
