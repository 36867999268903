import React from 'react';
import moment from 'moment';

import { TableWrapper } from '../../../../common/components/TableWrapper/TableWrapper';
import { Table } from '../../../../common/components/Table/Table';
import { TableRow } from '../../../../common/components/TableRow/TableRow';
import { TableCell } from '../../../../common/components/TableCell/TableCell';
import { Icon } from '../../../../common/components/Icon/Icon';
import { Link } from '../../../../common/components/Link/Link';
import { None } from '../../../../common/components/None/None';
import { AvatarName } from '../../../../common/components/AvatarName/AvatarName';

import { useTable } from '../../../../common/hooks/useTable';

import { URL_PEOPLE, URL_CONTRIBUTIONS } from '../../../../common/constants/url';

import { TableFilterDevsList } from '../TableFilterDevsList/TableFilterDevsList';
import { apiDevsList } from '../../api/api-devs-list';
import { IDevs, IDevsListFilter } from '../../typings/devs.type';

const columnsList = [
  {
    title: 'name',
    sort: false,
    column: 'name',
  },
  {
    title: 'email',
    sort: false,
    column: 'email',
  },
  {
    title: 'github',
    sort: false,
    column: 'github',
  },
  {
    title: 'stackoverflow',
    sort: false,
    column: 'stackoverflow',
  },
  {
    title: 'score',
    sort: true,
    column: 'score',
  },
  {
    title: 'contributions',
    sort: true,
    column: 'contributionsCount',
  },
  {
    title: 'last contribution',
    sort: true,
    column: 'lastContributionAt',
  },
  {
    title: 'active at',
    sort: true,
    column: 'activeAt',
  },
  {
    title: 'registered at',
    sort: true,
    column: 'createdAt',
  },
  {
    title: '',
    sort: false,
    column: '',
  },
];

// eslint-disable-next-line react/display-name
export const TableDevsList: React.FC = React.memo(() => {
  const { listData, sortData, filterData, loading, loadingLoadMore, setSort, setFilter, resetFilter, nextPage } =
    useTable<IDevs, IDevsListFilter>(
      apiDevsList,
      {
        searchQuery: '',
        activeAt: '',
      },
      { column: 'createdAt', dir: 'DESC' },
    );

  return (
    <TableWrapper
      heading
      headingTitle={'People / Contributers'}
      headingInfoBlock={{ count: listData?.count || 0, all: listData?.totalCount || 0 }}
      headingBtnBlock={[{ link: `${URL_PEOPLE}/add`, icon: 'plus', text: 'Add Contributer' }]}
      loadMoreShow={listData.data.length < listData?.count}
      loadMore={(e) => {
        e.preventDefault();
        nextPage();
      }}
      loading={loading}
      loadingLoadMore={loadingLoadMore}
    >
      <TableFilterDevsList disabled={false} filterUpdate={setFilter} resetFilter={resetFilter} props={filterData} />
      <Table
        loading={loading}
        sortColumn={sortData.column}
        sortDir={sortData.dir}
        columns={columnsList}
        changeSort={setSort}
      >
        {listData &&
          listData.data.map((current, index) => (
            <TableRow key={current.id + index} type="border-bottom">
              <TableCell>
                <AvatarName round image={current.avatarUrl}>
                  <Link type="ounter-url" to={`/devs/${current.id}`}>
                    {current.name || <None />}
                  </Link>
                </AvatarName>
              </TableCell>
              <TableCell>{current.email || <None />}</TableCell>
              <TableCell>
                {current.github ? (
                  <Link target="_blank" type="ounter-url" to={`https://github.com/${current.github}`}>
                    {current.github}
                  </Link>
                ) : (
                  <None />
                )}
              </TableCell>
              <TableCell>
                {current.stackoverflow ? (
                  <Link
                    target="_blank"
                    type="ounter-url"
                    to={`https://stackoverflow.com/users/${current.stackoverflow}`}
                  >
                    {current.stackoverflow}
                  </Link>
                ) : (
                  <None />
                )}
              </TableCell>
              <TableCell>{current.contributions.score || <None />}</TableCell>
              <TableCell>
                {current.contributions.contributionsCount ? (
                  <Link type="black-url" to={`${URL_CONTRIBUTIONS}?userId=${current.id}`}>
                    {current.contributions.contributionsCount} contributions
                  </Link>
                ) : (
                  <None />
                )}
              </TableCell>
              <TableCell>
                {current.contributions.lastContributionAt ? (
                  moment(current.contributions.lastContributionAt).fromNow()
                ) : (
                  <None />
                )}
              </TableCell>
              <TableCell>{current.activeAt ? moment(current.activeAt).fromNow() : <None />}</TableCell>
              <TableCell>{current.createdAt ? moment(current.createdAt).format('DD MMM, YYYY') : <None />}</TableCell>
              <TableCell>
                <Link to={`${URL_PEOPLE}/${current.id}`} type="btn-round">
                  <Icon name="editing" />
                </Link>
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </TableWrapper>
  );
});
