import React from 'react';

import { apiMailingsNotModeratedCount } from '../../features/mailing/api/api-not-moderated-count';

export type UserListContextT = {
  notModeratedCount: number;
  refreshNotModeratedCount: () => Promise<void>;
};

const UserListContext = React.createContext<UserListContextT>({
  notModeratedCount: 0,
  refreshNotModeratedCount: () => Promise.resolve(),
});

export const UserListProvider: React.FC = ({ children }) => {
  const [notModeratedCount, setNotModeratedCount] = React.useState(1);

  const refreshNotModeratedCount = React.useCallback(async () => {
    const data = await apiMailingsNotModeratedCount();

    if (data) {
      setNotModeratedCount(data.count);
    }
  }, []);

  const value = React.useMemo(
    () => ({
      notModeratedCount,
      refreshNotModeratedCount,
    }),
    [notModeratedCount, refreshNotModeratedCount],
  );

  return <UserListContext.Provider value={value}>{children}</UserListContext.Provider>;
};

export const useUserList = (): UserListContextT => {
  const context = React.useContext(UserListContext);

  if (!context) {
    throw new Error('useUserList must be used within an UserListProvider');
  }

  return context;
};
