import React from 'react';

import { View } from '../../../common/components/View/View';

import { TableMailingList } from '../components/TableMailingList/TableMailingList';

export const MailingListPage: React.FC = () => (
  <View type="list">
    <TableMailingList />
  </View>
);
