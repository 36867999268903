import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_LIST_MAILINGS } from '../../../common/constants/api';

import { IMailingsList } from '../typings/mailings.type';

export const apiMailingList = async ({ params = {} }: IApiRequest): Promise<IMailingsList | null> =>
  await httpGET<IMailingsList>({
    url: API_LIST_MAILINGS,
    params,
  });
