import { createContext } from 'react';

export interface IAuthContext {
  token: string | undefined;
  setToken: (token: string) => void;
}

export const authContext = createContext<IAuthContext>({
  token: undefined,
  setToken: (_token: string): void => undefined,
});
