import React from 'react';
import { AuthRoutes } from './routes/auth.routes';
import { PRODUCT_KEY, TOKEN_KEY } from '../common/utils/local-storage';
import { GuestRoutes } from './routes/guest.routes';
import { useLocalStorage } from '../common/hooks/useLocalStorage';
import { authContext } from '../common/contexts/authContext';

const searchParams = new URL(window.location.href).searchParams;
// const accessToken = searchParams.get(TOKEN_KEY);
const product = searchParams.get(PRODUCT_KEY);

// if (accessToken) {
//   localStorage.setItem(TOKEN_KEY, accessToken);
// }

if (product) {
  localStorage.setItem(PRODUCT_KEY, product);
}

export const RouterProvider: React.FC = () => {
  const [token, setToken] = useLocalStorage(TOKEN_KEY);
  const isEmptyToken = token === undefined || token === null || token === '';

  return (
    <authContext.Provider value={{ token, setToken }}>
      {isEmptyToken ? <GuestRoutes /> : <AuthRoutes />}
    </authContext.Provider>
  );
};
