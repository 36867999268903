import React from 'react';
import classnames from 'classnames';
import { Link as LinkReact } from 'react-router-dom';
import { Button } from 'antd';

import { Icon } from '../Icon/Icon';
import { Spinner } from '../Spinner/Spinner';

import s from './Link.module.scss';

interface LinkProps {
  to: string;
  className?: string;
  children?: React.ReactNode;
  type?: string;
  icon?: string;
  text?: string;
  target?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const Link: React.FC<LinkProps> = ({
  to,
  children,
  className,
  type = 'link',
  text = '',
  icon,
  target,
  loading,
  disabled = false,
  onClick,
}) => (
  <React.Fragment>
    {type === 'link' && (
      <LinkReact target={target ? target : undefined} className={classnames(s.link, className)} to={to}>
        {children}
      </LinkReact>
    )}
    {type === 'btn-danger' && (
      <LinkReact
        onClick={!loading && !disabled ? onClick : (e) => e.preventDefault()}
        target={target ? target : undefined}
        className={classnames(
          s.link,
          s.btnDanger,
          className,
          { [s.btnLoading]: loading },
          { [s.btnDisabled]: disabled },
        )}
        to={to}
      >
        {loading && <Spinner type="btn" />}
        {icon && <Icon name={icon} />} {children ? children : text}
      </LinkReact>
    )}
    {type === 'reset-filter' && (
      <a
        target={target ? target : undefined}
        className={classnames(s.link, s.btnResetFilter, className)}
        onClick={onClick}
        href={to}
      >
        {icon && <Icon name={icon} />} {children ? children : text}
      </a>
    )}
    {type === 'load-more' && (
      <Button
        className={s.antFormLoadMore}
        onClick={!loading ? onClick : (e) => e.preventDefault()}
        disabled={disabled}
        loading={loading}
      >
        {children}
      </Button>
    )}
    {type === 'black-url' && (
      <LinkReact target={target ? target : undefined} className={classnames(s.link, s.blackUrl, className)} to={to}>
        {icon && <Icon name={icon} />} {children ? children : text}
      </LinkReact>
    )}
    {type === 'ounter-url' && (
      <a target={target ? target : undefined} className={classnames(s.link, s.blackUrl, className)} href={to}>
        {icon && <Icon name={icon} />} {children ? children : text}
      </a>
    )}
    {type === 'ounter-bg-btn' && (
      <a target={target ? target : undefined} className={classnames(s.link, s.outBgBtn, className)} href={to}>
        {icon && <Icon name={icon} />} {children ? children : text}
      </a>
    )}
    {type === 'btn-round' && (
      <a
        target={target ? target : undefined}
        onClick={onClick}
        className={classnames(s.link, s.btnRound, className)}
        href={to}
      >
        {icon && <Icon name={icon} />} {children ? children : text}
      </a>
    )}
    {type === 'ant-form-save' && (
      <Button
        className={s.antFormSave}
        onClick={!disabled ? onClick : (e) => e.preventDefault()}
        disabled={disabled}
        loading={loading}
      >
        {children}
      </Button>
    )}
    {type === 'ant-form-archive' && (
      <Button
        className={s.antFormArchive}
        onClick={!disabled ? onClick : (e) => e.preventDefault()}
        disabled={disabled}
        loading={loading}
      >
        <span style={{ marginRight: '8px' }}>{!loading && <Icon name={'trash'} />}</span>
        {children}
      </Button>
    )}
  </React.Fragment>
);
