import React from 'react';
import classnames from 'classnames';

import { Icon } from '../Icon/Icon';

import s from './TableCell.module.scss';

interface ITableCell {
  children?: React.ReactNode;
  type?: 'th' | 'td';
  sortActive?: boolean;
  disabled?: boolean;
  sortDir?: 'DESC' | 'ASC' | null;
  style?: { [key: string]: string };
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const TableCell: React.FC<ITableCell> = ({
  disabled = false,
  children,
  type = 'td',
  sortDir = 'DESC',
  sortActive,
  onClick,
  style,
}) => (
  <React.Fragment>
    {type === 'td' && (
      <td style={style} className={classnames(s.tableCell, s.tableTd)}>
        {children}
      </td>
    )}
    {type === 'th' && (
      <th
        onClick={onClick}
        style={style}
        className={classnames(s.tableCell, s.tableTh, { [s.activeSort]: sortActive, [s.disabled]: disabled })}
      >
        {children}
        {sortActive && sortDir === 'DESC' && <Icon name="arrow-long-down" />}
        {sortActive && sortDir === 'ASC' && <Icon name="arrow-long-up" />}
      </th>
    )}
  </React.Fragment>
);
