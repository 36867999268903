import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_LIST_PRODUCTS } from '../../../common/constants/api';

import { IProductList } from '../typings/products.type';

export const apiProductList = async ({ params = {} }: IApiRequest): Promise<IProductList | null> =>
  await httpGET<IProductList>({
    url: API_LIST_PRODUCTS,
    params: Object.assign(params),
  });
