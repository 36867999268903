import { httpGET } from '../../../common/utils/http';
import { IApiRequest } from '../../../common/typings/api.type';

import { API_GET_PRODUCT } from '../../../common/constants/api';

import { IProduct } from '../typings/products.type';

export const apiGetProduct = async ({ options }: IApiRequest): Promise<IProduct | null> =>
  await httpGET<IProduct>({
    url: API_GET_PRODUCT,
    options,
  });
