import React from 'react';

import s from './Label.module.scss';

interface ILabel {
  children?: React.ReactNode;
  title?: string;
  required?: boolean;
}

export const Label: React.FC<ILabel> = ({ children, title, required }) => (
  <label className={s.labelWrapper}>
    <span className={s.label}>
      {title}
      {required && <span>*</span>}
    </span>
    {children}
  </label>
);
