import React from 'react';

import { Heading } from '../Heading/Heading';
import { Link } from '../Link/Link';
import { TableFoundCount } from '../TableFoundCount/TableFoundCount';

import { ITableWrapper, IHeadingBtnBlock } from './table-wrapper.type';

import s from './TableWrapper.module.scss';

export const TableWrapper: React.FC<ITableWrapper> = ({
  heading,
  headingTitle,
  headingInfoBlock,
  headingBtnBlock,
  children,
  loadMoreShow,
  loadMore,
  loadingLoadMore = false,
}) => (
  <div className={s.wrappeTableView}>
    {heading && (
      <Heading
        title={headingTitle || ''}
        infoBlock={headingInfoBlock && <TableFoundCount count={headingInfoBlock.count} all={headingInfoBlock.all} />}
        btnBlock={
          headingBtnBlock && (
            <>
              {headingBtnBlock.map((btn: IHeadingBtnBlock) => (
                <Link key={btn.link} to={btn.link} type="btn-danger" icon={btn.icon} text={btn.text} />
              ))}
            </>
          )
        }
      />
    )}
    <div className={s.wrappeTable}>{children}</div>
    {loadMore && loadMoreShow && (
      <div className={s.loadMoreWrapper}>
        <Link to={''} onClick={loadMore} loading={loadingLoadMore} type="load-more">
          Load more
        </Link>
      </div>
    )}
  </div>
);
