import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { Link } from '../../../../common/components/Link/Link';

import { TableWrapper } from '../../../../common/components/TableWrapper/TableWrapper';
import { Table } from '../../../../common/components/Table/Table';
import { TableRow } from '../../../../common/components/TableRow/TableRow';
import { TableCell } from '../../../../common/components/TableCell/TableCell';
import { useTable } from '../../../../common/hooks/useTable';
import { AvatarName } from '../../../../common/components/AvatarName/AvatarName';

import { useUserList } from '../../../../common/providers/UserListProvider';

import { TableFilterMainlingList } from '../TableFilterMailingList/TableFilterMailingList';
import { URL_CONTRIBUTIONS, URL_PEOPLE } from '../../../../common/constants/url';

import { IMailings, IMailingsListFilter } from '../../typings/mailings.type';
import { apiMailingList } from '../../api/api-mailing-list';
import { apiIgnoreMailing } from '../../api/api-ignore-mailing';
import { apiPublishMailing } from '../../api/api-publish-mailing';

const columnsList = [
  {
    title: 'Subject',
    sort: false,
    column: 'subject',
  },
  {
    title: 'E-mail',
    sort: false,
    column: 'fromEmail',
  },
  {
    title: 'Contributor',
    sort: false,
    column: 'userId',
  },
  {
    title: 'Date',
    sort: false,
    column: 'date',
  },
  {
    title: 'Status',
    sort: false,
    column: 'moderated',
  },
  {
    title: 'Contribution',
    sort: false,
    column: 'published',
  },
];

export const TableMailingList: React.FC = () => {
  const { refreshNotModeratedCount } = useUserList();
  const {
    listData,
    filterData,
    loading,
    loadingLoadMore,
    sortData,
    setFilter,
    resetFilter,
    nextPage,
    setSort,
    refreshData,
  } = useTable<IMailings, IMailingsListFilter>(apiMailingList, {
    status: 'new',
    searchQuery: '',
    dateFrom: '',
    dateTo: '',
    hideFiltered: true,
  });

  const [isActionProcessed, setIsActionProcessed] = useState(false);

  const handleIgnoreClick = useCallback(
    async (messageId: string) => {
      setIsActionProcessed(true);

      try {
        await apiIgnoreMailing({
          options: { messageId },
        });
        refreshData();
        refreshNotModeratedCount();
      } catch (e) {
        console.error(e);
      }

      setIsActionProcessed(false);
    },
    [refreshData, refreshNotModeratedCount],
  );

  const handlePublishClick = useCallback(
    async (messageId: string) => {
      setIsActionProcessed(true);

      try {
        await apiPublishMailing({
          options: { messageId },
        });
        refreshData();
        refreshNotModeratedCount();
      } catch (e) {
        console.error(e);
      }

      setIsActionProcessed(false);
    },
    [refreshData, refreshNotModeratedCount],
  );

  return (
    <TableWrapper
      heading
      headingTitle="Userlist answers"
      headingInfoBlock={{ count: listData?.count || 0, all: listData?.totalCount || 0 }}
      loadMoreShow={listData.data.length < listData?.count}
      loadMore={(e) => {
        e.preventDefault();
        nextPage();
      }}
      loading={loading}
      loadingLoadMore={loadingLoadMore}
    >
      <TableFilterMainlingList disabled={false} filterUpdate={setFilter} resetFilter={resetFilter} props={filterData} />

      <Table
        loading={loading}
        sortColumn={sortData.column}
        sortDir={sortData.dir}
        columns={columnsList}
        changeSort={setSort}
      >
        {listData &&
          listData.data.map((current) => (
            <TableRow key={current.messageId} type="border-bottom">
              <TableCell>
                <Link type="ounter-url" target="_blank" to={current.url}>
                  {current.subject}
                </Link>
              </TableCell>
              <TableCell>{current.fromEmail}</TableCell>
              <TableCell>
                {current.userId ? (
                  <AvatarName round image={current.userAvatar}>
                    <Link type="black-url" to={`${URL_PEOPLE}/${current.userId}`}>
                      {current.userName || current.userEmail}
                    </Link>
                  </AvatarName>
                ) : (
                  <Link
                    to={`${URL_PEOPLE}/add?email=${encodeURI(current.fromEmail)}`}
                    type="btn-danger"
                    icon="plus"
                    text="Add user"
                  />
                )}
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                {current.date ? moment(current.date).format('DD MMM, YYYY') : ''}
              </TableCell>
              <TableCell>
                {current.moderated ? (
                  current.published ? (
                    <Tag color="green">Published</Tag>
                  ) : (
                    <Tag color="red">Ignored</Tag>
                  )
                ) : (
                  <Link
                    type="ant-form-archive"
                    disabled={isActionProcessed}
                    to={'#'}
                    onClick={() => {
                      handleIgnoreClick(current.messageId);
                    }}
                  >
                    Ignore
                  </Link>
                )}
              </TableCell>
              <TableCell>
                {current.published ? (
                  <Link type="black-url" to={`${URL_CONTRIBUTIONS}/${current.contributionId}`}>
                    Contribution
                  </Link>
                ) : (
                  <Link
                    type="ant-form-save"
                    disabled={isActionProcessed || !current.userId}
                    onClick={() => {
                      handlePublishClick(current.messageId);
                    }}
                    to={'#'}
                  >
                    Publish
                  </Link>
                )}
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </TableWrapper>
  );
};
