import React from 'react';
import classnames from 'classnames';

import s from './View.module.scss';

interface IViewProps {
  children?: React.ReactNode;
  type: string;
}

export const View: React.FC<IViewProps> = ({ children, type }) => (
  <div className={classnames(s.view, { [s.viewList]: type === 'list', [s.viewForm]: type === 'form' })}>{children}</div>
);
